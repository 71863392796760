import React, { FC } from 'react';
import { Container, Grid, makeStyles, Theme, Box, Typography } from '@material-ui/core';
import Lottie from 'react-lottie';
import animationData from 'lotties/500.json';
import { ReadButton } from 'components/atoms';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textAlign: 'center',
    marginTop: theme.spacing(24),
    marginBottom: theme.spacing(24),
  },
  title: {
    fontWeight: 700,
    color: theme.palette.primary.dark,
    marginTop: theme.spacing(10),
  },
  subTitle: {
    fontWeight: 500,
    color: theme.palette.primary.dark,
    margin: theme.spacing(4, 0, 2),
  },
  text: {
    maxWidth: 550,
    margin: '0 auto',
    marginBottom: theme.spacing(5),
  },
}));

const ServerError: FC = () => {
  const classes = useStyles();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <Container className={classes.root} fixed>
      <Grid container justify="center" direction="row">
        <Grid item md={7} sm={12}>
          <Lottie options={defaultOptions} height={324} width={450} />
          <Typography variant="h1" className={classes.title}>
            500
          </Typography>
          <Typography variant="h3" className={classes.subTitle}>
            Internal server error
          </Typography>
          <Typography className={classes.text}>
            Unfortunately, the server was unable to complete your request. We’re already working on resolving the issue. Please
            come back later.
          </Typography>
          <ReadButton text="Go Home" href="/" orangeButton />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ServerError;
